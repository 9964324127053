const SecuritySetting = () => {
  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row">
        <div className="col-xl-4 col-lg-5 col-md-5 order-1 order-md-0">
          <div className="card mb-4">
            <div className="card-body">
              <div className="user-avatar-section">
                <div className="d-flex align-items-center flex-column">
                  <div className="user-info text-center">
                    <h4 className="mb-2">Name goes here</h4>
                    <span className="badge bg-label-secondary">role</span>
                  </div>
                </div>
              </div>
              <h5 className="pb-2 border-bottom mb-4">Details</h5>
              <div className="info-container">
                <ul className="list-unstyled">
                  <li className="mb-3">
                    <span className="fw-bold me-2">Username:</span>
                    <span>violet.dev</span>
                  </li>
                  <li className="mb-3">
                    <span className="fw-bold me-2">Email:</span>
                    <span>vafgot@vultukir.org</span>
                  </li>
                  <li className="mb-3">
                    <span className="fw-bold me-2">Status:</span>
                    <span className="badge bg-label-success">Active</span>
                  </li>
                  <li className="mb-3">
                    <span className="fw-bold me-2">Role:</span>
                    <span>Author</span>
                  </li>
                  <li className="mb-3">
                    <span className="fw-bold me-2">Contact:</span>
                    <span>(123) 456-7890</span>
                  </li>
                  <li className="mb-3">
                    <span className="fw-bold me-2">Country:</span>
                    <span>England</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-8 col-lg-7 col-md-7 order-0 order-md-1">
          <div className="card mb-4">
            <h5 className="card-header">Change Password from here</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecuritySetting;
