import { Route, Routes } from "react-router-dom";
import Login from "./pages/authentication/login";
import RequiredAuth from "./middleware/hooks/requiredLogin";
import PersitLogin from "./middleware/global/Persistent";
import Dashboard from "./pages/dashboard/dashboard";
import Layout from "./layouts/layouts";
import Client from "./pages/clients/clients";
import NewClient from "./pages/clients/components/newClient";
import Group from "./pages/clients/groups/groups";
import Saving from "./pages/savings/savings";
import Withdraws from "./pages/withdraws/withdraws";
import GeneralSaving from "./pages/savings/generalSaving";
import GeneralWithdraws from "./pages/withdraws/generalWithdraws";
import FixedDeposits from "./pages/fixedDeposits/fixedDeposits";
import Teams from "./pages/teams/teams";
import SecuritySetting from "./pages/securitySetting/securitySetting";
import Reports from "./pages/report/report";
import Communication from "./pages/communication/communication";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />

        <Route element={<PersitLogin />}>
          <Route element={<RequiredAuth />}>
            <Route path="" element={<Layout />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/clients/individuals" element={<Client />} />
              <Route path="/clients/individual/new" element={<NewClient />} />
              <Route path="/clients/groups" element={<Group/>} />
              <Route path="/accounting/savings" element={<Saving/>} />
              <Route path="/accounting/withdraws" element={<Withdraws/>} />
              <Route path="/accounting/general/savings" element={<GeneralSaving/>} />
              <Route path="/accounting/general/withdraws" element={<GeneralWithdraws/>} />
              <Route path="/accounting/fixed/deposits" element={<FixedDeposits />} />
              <Route path="/manage/team" element={<Teams />} />
              <Route path="/profile/user" element={<SecuritySetting />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/communication" element={<Communication />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
