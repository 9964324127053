import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import GroupTable from "./components/groupTable";
import useAxiosPrivate from "../../../middleware/hooks/useAxiosPrivate";
import { getClients } from "../../../middleware/api/clients/clients";
import { getGroups } from "../../../middleware/api/clients/groups/groups";

const Group = () => {
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [disabled, setDisabled] = useState(false);
  const axiosPrivate = useAxiosPrivate();

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await getGroups({axiosPrivate});
        setClients(response);
        setIsLoading(false);
      } catch (error) {
        //console.log(error)
      }
    };
    fetchClients();
  }, [axiosPrivate]);

  const refetch = async () => {
    setIsLoading(true);
    await fetchClients();
  };

  console.log(clients)

  return (
    <>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div classname="col-md-12 grid-margin stretch-card">
          <div classname="card">
            <div classname="card-body">
              <div className="d-flex align-items-center">
                <h4 className="card-title">Client management</h4>
                <div className="ml-auto">
                  <Link to="/clients/individual/new" className="btn btn-outline-primary btn-fw">
                    + Add new client
                  </Link>
                </div>
              </div>

              <p classname="card-description">Personal information</p>
              <GroupTable clients={clients} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Group;
