import { useEffect, useState } from "react";

export const getTeams = async ({axiosPrivate}) => {
  try {
    //const axiosPrivate = useAxiosPrivate();
    const URL = "/accounting/manage/teams";
    const controller = new AbortController();

    const response = await axiosPrivate.get(URL, {
      signal: controller.signal,
    });

    return response.data.data.users;
  } catch (error) {
    // Handle the error
    // console.error("Error in getClients:", error);
    throw error; // Optional: Rethrow the error to be handled elsewhere
  }
};


