import axios from "axios";

const BASE_URL = "https://api.furahabanking.com/v1/";
//const BASE_URL = 'http://localhost/MoneyLender'

export default axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: false,
});
