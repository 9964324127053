function NewClient() {
  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div class="bs-stepper wizard-numbered mt-2">
        <div class="bs-stepper-header">
          <div class="step active" data-target="#account-details">
            <button type="button" class="step-trigger" aria-selected="true">
              <span class="bs-stepper-circle">1</span>
              <span class="bs-stepper-label mt-1">
                <span class="bs-stepper-title">Account Details</span>
                <span class="bs-stepper-subtitle">Setup Account Details</span>
              </span>
            </button>
          </div>
          <div class="line">
            <i class="bx bx-chevron-right"></i>
          </div>
          <div class="step" data-target="#personal-info">
            <button type="button" class="step-trigger" aria-selected="false">
              <span class="bs-stepper-circle">2</span>
              <span class="bs-stepper-label mt-1">
                <span class="bs-stepper-title">Personal Info</span>
                <span class="bs-stepper-subtitle">Add personal info</span>
              </span>
            </button>
          </div>
          <div class="line">
            <i class="bx bx-chevron-right"></i>
          </div>
          <div class="step" data-target="#social-links">
            <button type="button" class="step-trigger" aria-selected="false">
              <span class="bs-stepper-circle">3</span>
              <span class="bs-stepper-label mt-1">
                <span class="bs-stepper-title">Social Links</span>
                <span class="bs-stepper-subtitle">Add social links</span>
              </span>
            </button>
          </div>
        </div>
        <div class="bs-stepper-content">
          <form>
            <div id="account-details" class="content active dstepper-block">
              <div class="content-header mb-3">
                <h6 class="mb-0">Account Details</h6>
                <small>Enter Your Account Details.</small>
              </div>
              <div class="row g-3">
                <div class="col-sm-6">
                  <label class="form-label" for="username">
                    Username
                  </label>
                  <input
                    type="text"
                    id="username"
                    class="form-control"
                    placeholder="johndoe"
                  />
                </div>
                <div class="col-sm-6">
                  <label class="form-label" for="email">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    class="form-control"
                    placeholder="john.doe@email.com"
                    aria-label="john.doe"
                  />
                </div>
                <div class="col-sm-6 form-password-toggle">
                  <label class="form-label" for="password">
                    Password
                  </label>
                  <div class="input-group input-group-merge">
                    <input
                      type="password"
                      id="password"
                      class="form-control"
                      placeholder="············"
                      aria-describedby="password2"
                    />
                    <span
                      class="input-group-text cursor-pointer"
                      id="password2"
                    >
                      <i class="bx bx-hide"></i>
                    </span>
                  </div>
                </div>
                <div class="col-sm-6 form-password-toggle">
                  <label class="form-label" for="confirm-password">
                    Confirm Password
                  </label>
                  <div class="input-group input-group-merge">
                    <input
                      type="password"
                      id="confirm-password"
                      class="form-control"
                      placeholder="············"
                      aria-describedby="confirm-password2"
                    />
                    <span
                      class="input-group-text cursor-pointer"
                      id="confirm-password2"
                    >
                      <i class="bx bx-hide"></i>
                    </span>
                  </div>
                </div>
                <div class="col-12 d-flex justify-content-between">
                  <button class="btn btn-label-secondary btn-prev" disabled="">
                    <i class="bx bx-chevron-left bx-sm ms-sm-n2"></i>
                    <span class="align-middle d-sm-inline-block d-none">
                      Previous
                    </span>
                  </button>
                  <button class="btn btn-primary btn-next">
                    <span class="align-middle d-sm-inline-block d-none me-sm-1">
                      Next
                    </span>
                    <i class="bx bx-chevron-right bx-sm me-sm-n2"></i>
                  </button>
                </div>
              </div>
            </div>
            <div id="personal-info" class="content">
              <div class="content-header mb-3">
                <h6 class="mb-0">Personal Info</h6>
                <small>Enter Your Personal Info.</small>
              </div>
              <div class="row g-3">
                <div class="col-sm-6">
                  <label class="form-label" for="first-name">
                    First Name
                  </label>
                  <input
                    type="text"
                    id="first-name"
                    class="form-control"
                    placeholder="John"
                  />
                </div>
                <div class="col-sm-6">
                  <label class="form-label" for="last-name">
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="last-name"
                    class="form-control"
                    placeholder="Doe"
                  />
                </div>
                <div class="col-sm-6">
                  <label class="form-label" for="country">
                    Country
                  </label>
                  <div class="position-relative">
                    <div class="position-relative">
                      <select
                        class="select2 select2-hidden-accessible"
                        id="country"
                        tabindex="-1"
                        aria-hidden="true"
                        data-select2-id="country"
                      >
                        <option label=" " data-select2-id="18"></option>
                        <option>UK</option>
                        <option>USA</option>
                        <option>Spain</option>
                        <option>France</option>
                        <option>Italy</option>
                        <option>Australia</option>
                      </select>
                      <span
                        class="select2 select2-container select2-container--default"
                        dir="ltr"
                        data-select2-id="17"
                        style={{ width: "auto" }}
                      >
                        <span class="selection">
                          <span
                            class="select2-selection select2-selection--single"
                            role="combobox"
                            aria-haspopup="true"
                            aria-expanded="false"
                            tabindex="0"
                            aria-disabled="false"
                            aria-labelledby="select2-country-container"
                          >
                            <span
                              class="select2-selection__rendered"
                              id="select2-country-container"
                              role="textbox"
                              aria-readonly="true"
                            >
                              <span class="select2-selection__placeholder">
                                Select value
                              </span>
                            </span>
                            <span
                              class="select2-selection__arrow"
                              role="presentation"
                            >
                              <b role="presentation"></b>
                            </span>
                          </span>
                        </span>
                        <span
                          class="dropdown-wrapper"
                          aria-hidden="true"
                        ></span>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <label class="form-label" for="language">
                    Language
                  </label>
                  <div class="dropdown bootstrap-select show-tick w-auto">
                    <select
                      class="selectpicker w-auto"
                      id="language"
                      data-style="btn-transparent"
                      data-icon-base="bx"
                      data-tick-icon="bx-check text-white"
                      multiple=""
                    >
                      <option>English</option>
                      <option>French</option>
                      <option>Spanish</option>
                    </select>
                    <button
                      type="button"
                      tabindex="-1"
                      class="btn dropdown-toggle bs-placeholder btn-transparent"
                      data-bs-toggle="dropdown"
                      role="combobox"
                      aria-owns="bs-select-1"
                      aria-haspopup="listbox"
                      aria-expanded="false"
                      title="Nothing selected"
                      data-id="language"
                    >
                      <div class="filter-option">
                        <div class="filter-option-inner">
                          <div class="filter-option-inner-inner">
                            Nothing selected
                          </div>
                        </div>{" "}
                      </div>
                    </button>
                    <div class="dropdown-menu ">
                      <div
                        class="inner show"
                        role="listbox"
                        id="bs-select-1"
                        tabindex="-1"
                        aria-multiselectable="true"
                      >
                        <ul
                          class="dropdown-menu inner show"
                          role="presentation"
                        ></ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 d-flex justify-content-between">
                  <button class="btn btn-primary btn-prev">
                    <i class="bx bx-chevron-left bx-sm ms-sm-n2"></i>
                    <span class="align-middle d-sm-inline-block d-none">
                      Previous
                    </span>
                  </button>
                  <button class="btn btn-primary btn-next">
                    <span class="align-middle d-sm-inline-block d-none me-sm-1">
                      Next
                    </span>
                    <i class="bx bx-chevron-right bx-sm me-sm-n2"></i>
                  </button>
                </div>
              </div>
            </div>
            <div id="social-links" class="content">
              <div class="content-header mb-3">
                <h6 class="mb-0">Social Links</h6>
                <small>Enter Your Social Links.</small>
              </div>
              <div class="row g-3">
                <div class="col-sm-6">
                  <label class="form-label" for="twitter">
                    Twitter
                  </label>
                  <input
                    type="text"
                    id="twitter"
                    class="form-control"
                    placeholder="https://twitter.com/abc"
                  />
                </div>
                <div class="col-sm-6">
                  <label class="form-label" for="facebook">
                    Facebook
                  </label>
                  <input
                    type="text"
                    id="facebook"
                    class="form-control"
                    placeholder="https://facebook.com/abc"
                  />
                </div>
                <div class="col-sm-6">
                  <label class="form-label" for="google">
                    Google+
                  </label>
                  <input
                    type="text"
                    id="google"
                    class="form-control"
                    placeholder="https://plus.google.com/abc"
                  />
                </div>
                <div class="col-sm-6">
                  <label class="form-label" for="linkedin">
                    LinkedIn
                  </label>
                  <input
                    type="text"
                    id="linkedin"
                    class="form-control"
                    placeholder="https://linkedin.com/abc"
                  />
                </div>
                <div class="col-12 d-flex justify-content-between">
                  <button class="btn btn-primary btn-prev">
                    <i class="bx bx-chevron-left bx-sm ms-sm-n2"></i>
                    <span class="align-middle d-sm-inline-block d-none">
                      Previous
                    </span>
                  </button>
                  <button class="btn btn-success btn-submit">Submit</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default NewClient;
