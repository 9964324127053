import { useEffect, useState } from "react";
import useAxiosPrivate from "../../middleware/hooks/useAxiosPrivate";
import { useLocation, useNavigate } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import { getTeams } from "../../middleware/api/teams/teams";
import TeamsTable from "./components/teamsTable";

const Teams = () => {
  const [teams, setTeams] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [disabled, setDisabled] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await getTeams({ axiosPrivate });
        setTeams(response);
        setIsLoading(false);
      } catch (error) {
        //console.log(error)
        toast.error(error?.response?.data?.messages);
      }
    };
    fetchTeams();
  }, [axiosPrivate]);

  const refetch = async () => {
    setIsLoading(true);
    await fetchTeams();
  };
  
  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row">
        <div className="col-md-12 grid-margin">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Manage Teams</h4>
                <TeamsTable teams={teams} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Teams;
