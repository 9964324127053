import DataTable from "react-data-table-component";

const columns = [
  {
    name: "A/C No.",
    selector: (row) => row.account_number,
    sortable: true
  },
  {
    name: "Account name",
    selector: (row) => row.account_name,
    sortable: true
  },
  {
    name: "Amount",
    selector: (row) => row.amount,
    sortable: true
  },
  {
    name: "Method",
    selector: (row) => row.method,
    sortable: true
  },
  {
    name: "Account type",
    selector: (row) => row.type,
    sortable: true
  },
  {
    name: "Timestamp",
    selector: (row) => row.timestamp,
    sortable: true
  },
  {
    name: "Status",
    selector: (row) => row.status,
    sortable: true
  },
  {
    name: "Reverse status",
    selector: (row) => row.reverse_status,
    sortable: true
  },
  {
    name: "Transaction code",
    selector: (row) => row.code,
    sortable: true
  },
  {
    name: "Action",
    selector: (row) => <div><span>view</span><span>delete</span></div>,
    sortable: true
  },
];


function GeneralWithdrawsTable({ withdraws }) {
  return <DataTable columns={columns} data={withdraws} pagination fixedHeader/>;
}

export default GeneralWithdrawsTable;
