import { useEffect, useState } from "react";
import useAxiosPrivate from "../../middleware/hooks/useAxiosPrivate";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import { Toaster, toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import { getEmails, sendEmail } from "../../middleware/api/communication/email";
import { getSms, sendSms } from "../../middleware/api/communication/sms";
import EmailTable from "./components/emailTable";
import SmsTable from "./components/smsTable";

const Communication = () => {
  const [emails, setEmails] = useState([]);
  const [sms, setSms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [disabled, setDisabled] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const {
    register: smsRegister,
    handleSubmit: smsHandleSubmit,
    reset: smsReset,
    formState: smsErrors,
  } = useForm();

  const {
    register: emailRegister,
    handleSubmit: emailHandleSubmit,
    reset: emailReset,
    formState: emailErrors,
  } = useForm();

  useEffect(() => {
    const fetchEmails = async () => {
      try {
        const response = await getEmails({ axiosPrivate });
        setEmails(response);
        setIsLoading(false);
      } catch (error) {
        toast.error(error?.response?.data?.messages);
      }
    };
    fetchEmails();
  }, [axiosPrivate]);

  useEffect(() => {
    const fetchSms = async () => {
      try {
        const response = await getSms({ axiosPrivate });
        setSms(response);
        setIsLoading(false);
      } catch (error) {
        toast.error(error?.response?.data?.messages);
      }
    };
    fetchSms();
  }, [axiosPrivate]);

  const refetch = async () => {
    setIsLoading(true);
    await fetchEmails();
    await fetchSms();
  };

  const sendingSms = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const response = await sendSms({ axiosPrivate, data });
      toast.success("Sms sent successfully!");
      progress.finish();
      setDisabled(false);
      smsReset();
      handleClose();
      fetchSms();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        // if (error?.response?.status === 401) {
        //   navigate("/login", { state: { from: location }, replace: true });
        // }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };

  const sendingEmail = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const response = await sendEmail({ axiosPrivate, data });
      toast.success("Email sent successfully!");
      progress.finish();
      setDisabled(false);
      emailReset();
      handleClose();
      fetchEmails();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        // if (error?.response?.status === 401) {
        //   navigate("/login", { state: { from: location }, replace: true });
        // }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <Toaster />
      <div className="row">
        <div className="col-md-12 grid-margin">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Communication</h4>
              <div className="nav-align-top mb-4">
                <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      type="button"
                      className="nav-link active"
                      role="tab"
                      data-bs-toggle="tab"
                      data-bs-target="#navs-top-sms"
                      aria-controls="navs-top-sms"
                      aria-selected="false"
                      tabindex="-1"
                    >
                      SMS
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      type="button"
                      className="nav-link"
                      role="tab"
                      data-bs-toggle="tab"
                      data-bs-target="#navs-top-email"
                      aria-controls="navs-top-email"
                      aria-selected="true"
                    >
                      Email
                    </button>
                  </li>
                </ul>
                <div className="tab-content">
                  <div
                    className="tab-pane fade"
                    id="navs-top-sms"
                    role="tabpanel"
                  >
                    <div className="d-flex align-items-center">
                      <h6 className="card-title">SMS</h6>
                      <div className="ms-auto">
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#sendSms"
                        >
                          Send Sms
                        </button>
                      </div>
                    </div>
                    <SmsTable sms={sms} />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="navs-top-email"
                    role="tabpanel"
                  >
                    <div className="d-flex align-items-center">
                      <h6 className="card-title">Emails</h6>
                      <div className="ms-auto">
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#sendEmail"
                        >
                          Send Email
                        </button>
                      </div>
                    </div>
                    <EmailTable emails={emails} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* modal to send sms */}
      <div
        className="modal fade"
        id="sendSms"
        tabIndex="-1"
        style={{ display: "none" }}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel3">
                Send SMS to all clients
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form onSubmit={smsHandleSubmit(sendingSms)}>
              <div className="modal-body">
                <div className="row g-2">
                  <div className="col mb-3">
                    {smsErrors?.message?.type === "required" && (
                      <p>
                        <small className="text-small text-danger">
                          This field is required
                        </small>
                      </p>
                    )}
                    <label htmlFor="smsbody" className="form-label">
                      Message
                    </label>
                    <textarea
                      id="smsbody"
                      className="form-control"
                      aria-label="With textarea"
                      name="message"
                      placeholder="Message should be less or equal to 160 characters per SMS"
                      rows="4"
                      {...smsRegister("message", { required: true })}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-label-secondary"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  disabled={disabled}
                  type="submit"
                  className="btn btn-primary"
                >
                  {!disabled ? "Send" : "Please Wait"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* modal to send email */}
      <div
        className="modal fade"
        id="sendEmail"
        tabIndex="-1"
        style={{ display: "none" }}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel3">
                Send email to all clients
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form onSubmit={emailHandleSubmit(sendingEmail)}>
              <div className="modal-body">
                <div className="row">
                  <div className="col mb-3">
                    {emailErrors?.subject?.type === "required" && (
                      <p>
                        <small className="text-small text-danger">
                          This field is required
                        </small>
                      </p>
                    )}
                    <label htmlFor="subject" className="form-label">
                      Subject
                    </label>
                    <input
                      type="text"
                      id="subject"
                      className="form-control"
                      placeholder="Enter subject"
                      name="subject"
                      {...emailRegister("subject", { required: true })}
                    />
                  </div>
                </div>
                <div className="row g-2">
                  <div className="col mb-3">
                    {emailErrors?.message?.type === "required" && (
                      <p>
                        <small className="text-small text-danger">
                          This field is required
                        </small>
                      </p>
                    )}
                    <label htmlFor="message" className="form-label">
                      Message
                    </label>
                    <textarea
                      id="message"
                      className="form-control"
                      aria-label="With textarea"
                      name="message"
                      placeholder="Enter message"
                      rows="4"
                      {...emailRegister("message", { required: true })}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-label-secondary"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  disabled={disabled}
                  type="submit"
                  className="btn btn-primary"
                >
                  {!disabled ? "Send" : "Please Wait"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Communication;
