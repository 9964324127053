import { useEffect, useState } from "react";

export const getGeneralWithdraws = async ({axiosPrivate}) => {
  try {
    //const axiosPrivate = useAxiosPrivate();
    const URL = "/accounting/savings/general/deposits";
    const controller = new AbortController();

    const response = await axiosPrivate.get(URL, {
      signal: controller.signal,
    });

    return response.data.data.transactions;
  } catch (error) {
    // Handle the error
    // console.error("Error in getClients:", error);
    throw error; // Optional: Rethrow the error to be handled elsewhere
  }
};

export const getGroupWithdraws = async ({axiosPrivate}) => {
    try {
      //const axiosPrivate = useAxiosPrivate();
      const URL = "/accounting/withdraw/general/withdraws";
      const controller = new AbortController();
  
      const response = await axiosPrivate.get(URL, {
        signal: controller.signal,
      });
  
      return response.data.data.transactions;
    } catch (error) {
      // Handle the error
      // console.error("Error in getClients:", error);
      throw error; // Optional: Rethrow the error to be handled elsewhere
    }
  };

// export const addClient = async (data, {axiosPrivate}) => {
//   try {
//     const URL = "/clients/individual";
//     const controller = new AbortController();

//     const response = await axiosPrivate.post(URL, data, {
//       signal: controller.signal,
//     });

//     return response;
//   } catch (error) {
//     throw error;
//   }
// };
