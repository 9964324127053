import DataTable from "react-data-table-component";

const columns = [
  {
    name: "Messsage",
    selector: (row) => row.message,
    sortable: true,
  },
  {
    name: "Message count",
    selector: (row) => row.length,
    sortable: true,
  },
  {
    name: "Sent by",
    selector: (row) => row.user,
    sortable: true,
  },
];

function SmsTable({ sms }) {
  return <DataTable columns={columns} data={sms} pagination fixedHeader />;
}

export default SmsTable;
