import DataTable from "react-data-table-component";

const columns = [
  {
    name: "Subject",
    selector: (row) => row.subject,
    sortable: true
  },
  {
    name: "Messsage",
    selector: (row) => row.message,
    sortable: true
  },
  {
    name: "Sent by",
    selector: (row) => row.user,
    sortable: true
  },
];


function EmailTable({ emails }) {
  return <DataTable columns={columns} data={emails} pagination fixedHeader/>;
}

export default EmailTable;
