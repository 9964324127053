import { useEffect, useState } from "react";
import { addClient, getClients } from "../../middleware/api/clients/clients";
import useAxiosPrivate from "../../middleware/hooks/useAxiosPrivate";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ClientsTable from "./components/clientsTable";
import ProgressBar from "@badrap/bar-of-progress";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import NewClient from "./components/newClient";

const Client = () => {
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [disabled, setDisabled] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await getClients({ axiosPrivate });
        setClients(response);
        setIsLoading(false);
      } catch (error) {
        //console.log(error)
      }
    };
    fetchClients();
  }, [axiosPrivate]);

  const refetch = async () => {
    setIsLoading(true);
    await fetchClients();
  };

  console.log(clients);

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div class="row">
        <div class="col-md-4 mb-4 stretch-card transparent">
          <div class="card card-light">
            <div class="card-body">
              <p class="mb-4">Today’s individual</p>
              <p class="fs-30 mb-2">4006</p>
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-4 stretch-card transparent">
          <div class="card card-light">
            <div class="card-body">
              <p class="mb-4">Total general</p>
              <p class="fs-30 mb-2">61344</p>
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-4 stretch-card transparent">
          <div class="card card-light">
            <div class="card-body">
              <p class="mb-4">Total savings</p>
              <p class="fs-30 mb-2">61344</p>
            </div>
          </div>
        </div>
      </div>
      <div classname="row">
        <div classname="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div className="d-flex align-items-center">
                <h4 className="card-title">Client Management</h4>
                <div className="ms-auto">
                  <Link
                    to="/clients/individual/new"
                    className="btn btn-outline-primary btn-fw"
                  >
                    + Add new client
                  </Link>
                </div>
              </div>

              <p class="card-description">Select account type</p>
              <ClientsTable clients={clients} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Client;
