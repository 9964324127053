import { useEffect, useState } from "react";
import useAxiosPrivate from "../../middleware/hooks/useAxiosPrivate";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import {
  getGroupSavings,
  getIndividualSavings,
} from "../../middleware/api/savings/savings";
import IndividualSavingsTable from "./components/individualSavingsTable";
import GroupSavingsTable from "./components/groupSavingsTable";

const Saving = () => {
  const [savings, setSavings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [disabled, setDisabled] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const fetchIndividualSavings = async () => {
      try {
        const response = await getIndividualSavings({ axiosPrivate });
        setSavings(response);
        setIsLoading(false);
      } catch (error) {
        //console.log(error)
      }
    };
    fetchIndividualSavings();
  }, [axiosPrivate]);

  useEffect(() => {
    const fetchGroupSavings = async () => {
      try {
        const response = await getGroupSavings({ axiosPrivate });
        setSavings(response);
        setIsLoading(false);
      } catch (error) {
        //console.log(error)
      }
    };
    fetchGroupSavings();
  }, [axiosPrivate]);

  const refetch = async () => {
    setIsLoading(true);
    await fetchIndividualSavings();
  };

  console.log(savings);

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div class="row">
        <div class="col-md-4 mb-4 stretch-card transparent">
          <div class="card card-light">
            <div class="card-body">
              <p class="mb-4">Today’s individual</p>
              <p class="fs-30 mb-2">4006</p>
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-4 stretch-card transparent">
          <div class="card card-light">
            <div class="card-body">
              <p class="mb-4">Total general</p>
              <p class="fs-30 mb-2">61344</p>
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-4 stretch-card transparent">
          <div class="card card-light">
            <div class="card-body">
              <p class="mb-4">Total savings</p>
              <p class="fs-30 mb-2">61344</p>
            </div>
          </div>
        </div>
      </div>
      <div classname="row">
        <div classname="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Savings</h4>
              <p class="card-description">Select account type</p>
              <div class="nav-align-top mb-4">
                <ul class="nav nav-tabs" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button
                      type="button"
                      class="nav-link active"
                      role="tab"
                      data-bs-toggle="tab"
                      data-bs-target="#navs-top-individual"
                      aria-controls="navs-top-individual"
                      aria-selected="false"
                      tabindex="-1"
                    >
                      Individual Savings
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      type="button"
                      class="nav-link"
                      role="tab"
                      data-bs-toggle="tab"
                      data-bs-target="#navs-top-group"
                      aria-controls="navs-top-group"
                      aria-selected="true"
                    >
                      Group Savings
                    </button>
                  </li>
                </ul>
                <div class="tab-content">
                  <div class="tab-pane fade" id="navs-top-individual" role="tabpanel">
                  <IndividualSavingsTable savings={savings} />
                  </div>
                  <div
                    class="tab-pane fade"
                    id="navs-top-group"
                    role="tabpanel"
                  >
                    <GroupSavingsTable savings={savings} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Saving;
