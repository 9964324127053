import { useEffect, useState } from "react";

export const getGroups = async ({axiosPrivate}) => {
  try {
    //const axiosPrivate = useAxiosPrivate();
    const URL = "/clients/groups";
    const controller = new AbortController();

    const response = await axiosPrivate.get(URL, {
      signal: controller.signal,
    });

    return response.data.data.clients;
  } catch (error) {
    // Handle the error
    // console.error("Error in getClients:", error);
    throw error; // Optional: Rethrow the error to be handled elsewhere
  }
};

export const addGroup = async (data, {axiosPrivate}) => {
  try {
    const URL = "/clients/individual";
    const controller = new AbortController();

    const response = await axiosPrivate.post(URL, data, {
      signal: controller.signal,
    });

    return response;
  } catch (error) {
    throw error;
  }
};
