import { useEffect, useState } from "react";

export const getEmails = async ({axiosPrivate}) => {
  try {
    //const axiosPrivate = useAxiosPrivate();
    const URL = "/communication/general/emails";
    const controller = new AbortController();

    const response = await axiosPrivate.get(URL, {
      signal: controller.signal,
    });

    return response.data.data.emails;
  } catch (error) {
    // Handle the error
    // console.error("Error in getClients:", error);
    throw error; // Optional: Rethrow the error to be handled elsewhere
  }
};

export const sendEmail = async ({axiosPrivate, data}) => {
  try {
    const URL = "/communication/general/emails";
    const controller = new AbortController();

    const response = await axiosPrivate.post(URL,
        JSON.stringify({subject: data.subject, message: data.message }),
        {
          signal: controller.signal,
        }
      );

    return response.data.messages;
  } catch (error) {
    throw error
  }
}


