const Reports = () => {
  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div class="card p-5">
        <h4 className="card-title text-center">Reports</h4>
        <div class="card-body">
          <div className="d-flex align-items-center">
            <div className="row">
              <div class="col-md-3 col-lg-3 mb-4 order-lg-1 order-2">
                <div class="card">
                  <div class="d-flex align-items-end">
                    <div class="card-body text-center">
                      <h6 class="card-title mb-1 text-nowrap">
                        General Ledger
                      </h6>
                      <small class="d-block mb-3 text-nowrap">
                        Best seller of the month
                      </small>
                      <a href="javascript:;" class="btn btn-sm btn-primary">
                        Click to view
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-lg-3 mb-4 order-lg-1 order-2">
                <div class="card">
                  <div class="d-flex align-items-end">
                    <div class="card-body text-center">
                      <h6 class="card-title mb-1 text-nowrap">Cash Book</h6>
                      <small class="d-block mb-3 text-nowrap">
                        Best seller of the month
                      </small>
                      <a href="javascript:;" class="btn btn-sm btn-primary">
                        Click to view
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-lg-3 mb-4 order-lg-1 order-2">
                <div class="card">
                  <div class="d-flex align-items-end">
                    <div class="card-body text-center">
                      <h6 class="card-title mb-1 text-nowrap">
                        General Account Balances
                      </h6>
                      <small class="d-block mb-3 text-nowrap">
                        Best seller of the month
                      </small>
                      <a href="javascript:;" class="btn btn-sm btn-primary">
                        Click to view
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-lg-3 mb-4 order-lg-1 order-2">
                <div class="card">
                  <div class="d-flex align-items-end">
                    <div class="card-body text-center">
                      <h6 class="card-title mb-1 text-nowrap">
                        Loan Portfolio
                      </h6>
                      <small class="d-block mb-3 text-nowrap">
                        Best seller of the month
                      </small>
                      <a href="javascript:;" class="btn btn-sm btn-primary">
                        Click to view
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-lg-3 mb-4 order-lg-1 order-2">
                <div class="card">
                  <div class="d-flex align-items-end">
                    <div class="card-body text-center">
                      <h6 class="card-title mb-1 text-nowrap">Trial Balance</h6>
                      <small class="d-block mb-3 text-nowrap">
                        Best seller of the month
                      </small>
                      <a href="javascript:;" class="btn btn-sm btn-primary">
                        Click to view
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-lg-3 mb-4 order-lg-1 order-2">
                <div class="card">
                  <div class="d-flex align-items-end">
                    <div class="card-body text-center">
                      <h6 class="card-title mb-1 text-nowrap">
                        Income Statement
                      </h6>
                      <small class="d-block mb-3 text-nowrap">
                        Best seller of the month
                      </small>
                      <a href="javascript:;" class="btn btn-sm btn-primary">
                        Click to view
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-lg-3 mb-4 order-lg-1 order-2">
                <div class="card">
                  <div class="d-flex align-items-end">
                    <div class="card-body text-center">
                      <h6 class="card-title mb-1 text-nowrap">Balance Sheet</h6>
                      <small class="d-block mb-3 text-nowrap">
                        Best seller of the month
                      </small>
                      <a href="javascript:;" class="btn btn-sm btn-primary">
                        Click to view
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports;
