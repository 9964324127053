import { memo } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import useLogout from "../middleware/hooks/useLogout";
import { Button } from "bootstrap";

const SideBar = memo(({ handleCloseMenu }) => {
  const logout = useLogout();
  const navigate = useNavigate();

  const signOut = async () => {
    await logout;
    await localStorage.removeItem("verify");
    //navigate('/login');
  };

  return (
    <aside
      id="layout-menu"
      className="layout-menu menu-vertical menu bg-menu-theme"
    >
      <div className="app-brand demo ">
        <a href="#" className="app-brand-link">
          <span className="app-brand-logo demo"></span>
          <span className="app-brand-text demo menu-text fw-bolder ms-2">
            Furaha
          </span>
        </a>
        <a href="#" className="layout-menu-toggle menu-link text-large ms-auto">
          <i className="bx bx-chevron-left bx-sm align-middle"></i>
        </a>
      </div>
      <div className="menu-inner-shadow"></div>
      <ul className="menu-inner py-1">
        <NavLink
          exact
          to="/dashboard"
          className={({ isActive }) =>
            isActive ? "menu-item active" : "menu-item"
          }
        >
          <Link to="/dashboard" className="menu-link">
            <i className="menu-icon tf-icons bx bx-home-circle"></i>
            <div data-i18n="Dashboard">Dashboard</div>
          </Link>
        </NavLink>
        <NavLink
          exact
          to="/accounting/savings"
          className={({ isActive }) =>
            isActive ? "menu-item active" : "menu-item"
          }
        >
          <Link to="/accounting/savings" className="menu-link">
            <i className="menu-icon tf-icons bx bx-wallet"></i>
            <div data-i18n="Wallet">Savings</div>
          </Link>
        </NavLink>
        <NavLink
          exact
          to="/accounting/general/savings"
          className={({ isActive }) =>
            isActive ? "menu-item active" : "menu-item"
          }
        >
          <Link to="/accounting/general/savings" className="menu-link">
            <i className="menu-icon tf-icons bx  bxs-credit-card-front"></i>
            <div data-i18n="GeneralSavings">General Savings</div>
          </Link>
        </NavLink>
        <NavLink
          exact
          to="/accounting/withdraws"
          className={({ isActive }) =>
            isActive ? "menu-item active" : "menu-item"
          }
        >
          <Link to="/accounting/withdraws" className="menu-link">
            <i className="menu-icon tf-icons bx bx-money-withdraw"></i>
            <div data-i18n="Withdraw">Withdraws</div>
          </Link>
        </NavLink>
        <NavLink
          exact
          to="/accounting/general/withdraws"
          className={({ isActive }) =>
            isActive ? "menu-item active" : "menu-item"
          }
        >
          <Link to="/accounting/general/withdraws" className="menu-link">
            <i className="menu-icon tf-icons bx bx-money"></i>
            <div data-i18n="Money">General Withdraws</div>
          </Link>
        </NavLink>
        <NavLink
          exact
          to="/accounting/fixed/deposits"
          className={({ isActive }) =>
            isActive ? "menu-item active" : "menu-item"
          }
        >
          <Link to="/accounting/fixed/deposits" className="menu-link">
            <i className="menu-icon tf-icons bx bxs-bank"></i>
            <div data-i18n="Bank">Fixed Deposits</div>
          </Link>
        </NavLink>
        <NavLink
          exact
          to="/communication"
          className={({ isActive }) =>
            isActive ? "menu-item active" : "menu-item"
          }
        >
          <Link to="/communication" className="menu-link">
            <i className="menu-icon tf-icons bx bx-envelope"></i>
            <div data-i18n="Envelope">Communication</div>
          </Link>
        </NavLink>
        <NavLink
          exact
          to="/reports"
          className={({ isActive }) =>
            isActive ? "menu-item active" : "menu-item"
          }
        >
          <Link to="/reports" className="menu-link">
            <i className="menu-icon tf-icons bx bxs-report"></i>
            <div data-i18n="Report">Reports</div>
          </Link>
        </NavLink>
        <NavLink
          exact
          to="/manage/team"
          className={({ isActive }) =>
            isActive ? "menu-item active" : "menu-item"
          }
        >
          <Link to="/manage/team" className="menu-link">
            <i className="menu-icon tf-icons bx bx-user-pin"></i>
            <div data-i18n="Users">Manage team</div>
          </Link>
        </NavLink>
        <NavLink
          exact
          to="/profile/user"
          className={({ isActive }) =>
            isActive ? "menu-item active" : "menu-item"
          }
        >
          <Link to="/profile/user" className="menu-link">
            <i className="menu-icon tf-icons bx bx-lock-alt"></i>
            <div data-i18n="Savings">Security Settings</div>
          </Link>
        </NavLink>
        <NavLink
          exact
          to="/activity"
          className={({ isActive }) =>
            isActive ? "menu-item active" : "menu-item"
          }
        >
          <Link to="/activity" className="menu-link">
            <i className="menu-icon tf-icons bx bx-task"></i>
            <div data-i18n="Savings">Activity</div>
          </Link>
        </NavLink>
        
        <li
          className="menu-item"
        >
          <button className="btn menu-link">
            <i className="menu-icon tf-icons bx bx-log-out-circle"></i>
            <div data-i18n="Savings">Logout</div>
          </button>
        </li>
        <li className="menu-item active open">
                     <a href="javascript:void(0);" className="menu-link menu-toggle">
                        <i className="menu-icon tf-icons bx bx-log-out-circle"></i>
                        <div data-i18n="Dashboards">Dashboards</div>
                     </a>
                     <ul className="menu-sub">
                        <li className="menu-item active">
                           <a href="dashboards-analytics.html" className="menu-link">
                              <div data-i18n="Analytics">Analytics</div>
                           </a>
                        </li>
                        <li className="menu-item">
                           <a href="dashboards-crm.html" className="menu-link">
                              <div data-i18n="CRM">CRM</div>
                           </a>
                        </li>
                        <li className="menu-item">
                           <a href="dashboards-ecommerce.html" className="menu-link">
                              <div data-i18n="eCommerce">eCommerce</div>
                           </a>
                        </li>
                     </ul>
                  </li>
      </ul>
    </aside>
  );
});

export default SideBar;
