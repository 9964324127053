import { useEffect, useState } from "react";
import useAxiosPrivate from "../../middleware/hooks/useAxiosPrivate";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import FixedDepositsTable from "./components/fixedDepositsTable";
import { getFixedDeposits } from "../../middleware/api/fixedDeposits/deposits";

const FixedDeposits = () => {
  const [deposits, setDeposits] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [disabled, setDisabled] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const fetchDeposits = async () => {
      try {
        const response = await getFixedDeposits({ axiosPrivate });
        setDeposits(response);
        setIsLoading(false);
      } catch (error) {
        //console.log(error)
      }
    };
    fetchDeposits();
  }, [axiosPrivate]);

  const refetch = async () => {
    setIsLoading(true);
    await fetchDeposits();
  };

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div classname="row">
        <div classname="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div className="d-flex align-items-center">
                <h4 className="card-title">Fixed Deposits</h4>
                <div className="ms-auto">
                  <Link
                    to="/clients/individual/new"
                    className="btn btn-outline-primary btn-fw"
                  >
                    + Add fixed deposit
                  </Link>
                </div>
              </div>

              <FixedDepositsTable deposits={deposits} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FixedDeposits;
