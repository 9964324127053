import ProgressBar from "@badrap/bar-of-progress";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { onLogin } from "../../middleware/api/sessions";
import { Toaster, toast } from "react-hot-toast";
import { errorColor, whiteColor } from "../../services/constants/color";
import useAuth from "../../middleware/hooks/useAuth";

function Login() {
  const url =
    "https://images.unsplash.com/photo-1491466424936-e304919aada7?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1949&amp;q=80";
  const progress = new ProgressBar({ color: errorColor });
  const navigate = useNavigate();
  const location = useLocation();
  const { setAuth } = useAuth();
  const [disabled, setDisabled] = useState(false);

  const from = location.state?.from?.pathname || "/dashboard";

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onLoginAction = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const response = await onLogin(data);
      const accessToken = response?.access_token;
      const refreshToken = response?.refresh_token;
      const sessionId = response?.session_id;

      setAuth({ sessionId, accessToken, refreshToken });
      const verify = {
        refresh: refreshToken,
        id: sessionId,
        access: accessToken,
      };
      localStorage["verify"] = JSON.stringify(verify);

      navigate(from, { replace: true });
      progress.finish();
    } catch (error) {
      if (!error?.response) {
        setDisabled(false);
        toast.error("No server response", {
          style: { background: errorColor, color: whiteColor },
        });
        progress.finish();
      } else {
        setDisabled(false);
        progress.finish();
        toast.error(error?.response?.data?.messages, {
          style: { background: errorColor, color: whiteColor },
        });
      }
    }
  };

  return (
    <div className="container-xxl">
      <div className="authentication-wrapper authentication-basic container-p-y">
        <div className="authentication-inner">
          <div className="card">
            <div className="card-body">
              <h4 className="mb-2">Welcome to Furaha! 👋</h4>
              <p className="mb-4">
                Please sign-in to your account and start the adventure
              </p>

              <form
                id="formAuthentication"
                className="mb-3"
                onSubmit={handleSubmit(onLoginAction)}
              >
                <div className="mb-3">
                {errors?.username?.type === 'required' && <p className="text-small m-0 text-danger help-block">Email address is required</p>}
                    {errors?.username?.type === 'pattern' && <p className="text-small m-0 text-danger help-block">Provide a valid email address</p>}
                  <label htmlFor="email" className="form-label">
                    Email or Username
                  </label>
                  <input
                    type="email"
                    id="username"
                    className="form-control"
                    placeholder="email address"
                    autoCapitalize="off"
                    {...register("username", {required: true, pattern: {value: /^\S+@\S+$/i}})}
                  />
                </div>
                <div className="mb-3 form-password-toggle">
                  <div className="input-group input-group-merge">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="password"
                      id="password"
                      autoComplete="off"
                      {...register("password", {required: true})}
                    />
                    <span className="input-group-text cursor-pointer">
                      <i className="bx bx-hide"></i>
                    </span>
                  </div>
                </div>
                <div className="mb-3">
                  <button className="btn btn-primary d-grid w-100" type="submit" disabled={disabled}
                    >
                      {!disabled && "SIGN IN"} {disabled && "Please wait..."}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
