import DataTable from "react-data-table-component";

const columns = [
  {
    name: "Type",
    selector: (row) => "Fixed deposits",
    sortable: true
  },
  {
    name: "Account number",
    selector: (row) => row.account_number,
    sortable: true
  },
  {
    name: "Fixed Amount",
    selector: (row) => row.amount,
    sortable: true
  },
  {
    name: "Amount to receive",
    selector: (row) => row.return_amount,
    sortable: true
  },
  {
    name: "Start date",
    selector: (row) => row.start,
    sortable: true
  },
  {
    name: "End date",
    selector: (row) => row.end,
    sortable: true
  },
  {
    name: "Transaction code",
    selector: (row) => row.code,
    sortable: true
  },
  {
    name: "Status",
    selector: (row) => row.status,
    sortable: true
  },
  {
    name: "Action",
    selector: (row) => <div className="flex"><span>receipt</span> <span>delete</span></div>,
    sortable: true
  },
];


function FixedDepositsTable({ deposits }) {
  return <DataTable columns={columns} data={deposits} pagination fixedHeader/>;
}

export default FixedDepositsTable;
