import { Outlet } from "react-router-dom";
import SideBar from "./sidebar";
import Header from "./header";
import Footer from "./footer";

const Layout = () => {
  return (
    <div className="layout-wrapper layout-content-navbar  ">
      <div className="layout-container">
        <SideBar />
        <div className="layout-page">
          <Header />
          <div className="content-wrapper">
            <Outlet />
            <Footer />
            <div className="content-backdrop fade"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
