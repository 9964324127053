import DataTable from "react-data-table-component";

const columns = [
  {
    name: "Firstname",
    selector: (row) => row.firstname,
    sortable: true
  },
  {
    name: "Lastname",
    selector: (row) => row.lastname,
    sortable: true
  },
  {
    name: "Gender",
    selector: (row) => row.gender,
    sortable: true
  },
  {
    name: "Contact",
    selector: (row) => row.contact,
    sortable: true
  },
  {
    name: "Email",
    selector: (row) => row.email,
    sortable: true
  },
  {
    name: "Float Balance",
    selector: (row) => row.balance,
    sortable: true
  },
  {
    name: "Status",
    selector: (row) => row.status,
    sortable: true
  },
  {
    name: "Action",
    selector: (row) => <div><span>view</span><span>delete</span></div>,
    sortable: true
  },
];


function TeamsTable({ teams }) {
  return <DataTable columns={columns} data={teams} pagination fixedHeader/>;
}

export default TeamsTable;
