import DataTable from "react-data-table-component";

const columns = [
  {
    name: "A/C No.",
    selector: (row) => row.account,
    sortable: true
  },
  {
    name: "Firstname",
    selector: (row) => row.firstname,
    sortable: true
  },
  {
    name: "Lastname",
    selector: (row) => row.lastname,
    sortable: true
  },
  {
    name: "Gender",
    selector: (row) => row.gender,
    sortable: true
  },
  {
    name: "Contact",
    selector: (row) => row.contact,
    sortable: true
  },
  {
    name: "Action",
    selector: (row) => <div><span>view</span><span>delete</span></div>,
    sortable: true
  },
];


function GroupSavingsTable({ savings }) {
  return <DataTable columns={columns} data={savings} pagination fixedHeader/>;
}

export default GroupSavingsTable;
